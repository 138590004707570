::-webkit-scrollbar {
    width: 12px;
    height: 20px;
}
::-webkit-scrollbar-track {
    background-color: #EAF1F6;
} 
::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 6px;
    border: 2px solid #f1f1f1;
    cursor: pointer;
}  
/* ::-webkit-scrollbar-thumb:hover {
    background-color: #1E40AF;
} */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
